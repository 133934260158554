<template>
  <div class="account-page" ref="formContainer">
    <div class="main-wrapper">
      <div class="account-content">
        <div class="container">
          <!-- Account Logo -->
          <div class="account-logo">
            <router-link to="admin-dashboard">
              <img src="@/assets/img/logo2.png" alt="Workwise" />
            </router-link>
          </div>
          <!-- /Account Logo -->

          <div class="account-box">
            <div class="account-wrapper">
              <h3 class="account-title">Delete Account</h3>
              <p class="text-success font-bold" v-if="successMessage">
                {{ successMessage }}
              </p>

              <div v-if="userDetails && otpSent" class="mb-3">
                <p>
                  <strong>Name:</strong> {{ userDetails.first_name }}
                  {{ userDetails.last_name }}
                </p>
                <p>
                  <strong>Email:</strong>
                  {{ maskEmail(userDetails.email) }}
                </p>
              </div>

              <Form
                @submit="onSubmit"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="input-block mb-3 mt-3">
                  <label for="userinput" class="col-form-label"
                    >Email / Employee ID</label
                  >
                  <Field
                    type="text"
                    class="form-control"
                    v-model="form.userinput"
                    name="userinput"
                    id="userinput"
                    :disabled="otpSent"
                    :class="{ 'is-invalid': errors.userinput }"
                  />
                  <div class="invalid-feedback">{{ errors.userinput }}</div>
                </div>

                <div v-if="otpSent" class="input-block mb-3 mt-3">
                  <label for="otp" class="col-form-label">Enter OTP</label>
                  <Field
                    type="text"
                    class="form-control"
                    v-model="form.otp"
                    name="otp"
                    id="otp"
                    :class="{ 'is-invalid': errors.otp }"
                  />
                  <div class="invalid-feedback">{{ errors.otp }}</div>
                </div>

                <div class="input-block mb-4 text-center">
                  <button
                    class="btn btn-primary account-btn"
                    type="submit"
                    :disabled="disabledButton"
                  >
                    {{ otpSent ? "Delete Account" : "Request OTP" }}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from "yup";
import { Form, Field } from "vee-validate";
import axios from "axios";
import { notification } from "ant-design-vue";

export default {
  components: { Form, Field },
  data() {
    return {
      form: {
        userinput: null,
        otp: null,
      },
      successMessage: null,
      disabledButton: false,
      otpSent: false,
      userDetails: null,
    };
  },
  computed: {
    schema() {
      return this.otpSent
        ? Yup.object().shape({
            otp: Yup.string()
              .matches(/^\d{4}$/, "OTP must be 4 digits only")
              .required("OTP is required"),
          })
        : Yup.object().shape({
            userinput: Yup.string().required("Email/Employee ID is required"),
          });
    },
  },
  methods: {
    maskEmail(email) {
      if (!email.includes("@")) return email;
      const [localPart, domain] = email.split("@");
      const maskedLocalPart =
        localPart[0] + "*".repeat(localPart.length - 3) + localPart.slice(-1);
      return `${maskedLocalPart}@${domain}`;
    },
    onSubmit() {
      this.disabledButton = true;
      const loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: false,
      });

      if (!this.otpSent) {
        axios
          .post("/delete-confirmation-otp", { userinput: this.form.userinput })
          .then((response) => {
            this.successMessage = response?.data?.message;
            this.userDetails = response?.data?.data?.user;
            this.otpSent = true;
            this.notify(response.data.message, true);
          })
          .catch(this.handleError)
          .finally(() => {
            this.disabledButton = false;
            loader.hide();
          });
      } else {

        axios
          .post("/delete-account", {
            id: this.userDetails.id,
            otp_code: Number(this.form.otp),
          })
          .then((response) => {
            this.successMessage = response?.data?.message;
            this.notify(response.data.message, true);
            this.$router.push({ name: "login" }).catch((error) => {});
          })
          .catch(this.handleError)
          .finally(() => {
            this.disabledButton = false;
            loader.hide();
          });
      }
    },
    handleError(error) {
      const errorMessage =
        error.response?.data?.message || "Server Error. Please try again.";
      this.notify(errorMessage, false);
    },
    notify(message, isSuccess) {
      notification.open({
        message,
        placement: "topRight",
        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
        style: {
          background: isSuccess
            ? process.env.VUE_APP_SUCCESS_COLOR
            : process.env.VUE_APP_WARNING_COLOR,
        },
      });
    },
  },
};
</script>
