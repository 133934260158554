<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <shift-header
          :title="title"
          :path="path"
          :text="text"
          :text1="text1"
          :text2="text2"
          :text3="text3"
          :text4="text4"
          @add-shift="openAddShiftModel"
          @add-schedule="openAddScheduleModel"
          @add-dept-schedule="openAddDeptScheduleModel"
        />
        <!-- /Page Header -->

        <!-- Content Starts -->
        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                @change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'shift_name'">
                    <div>{{ record.shift_name }}</div>
                  </template>
                  <template v-if="column.key === 'min_start_time'">
                    <div>{{ record.min_start_time }}</div>
                  </template>
                  <template v-if="column.key === 'start_time'">
                    <div>{{ record.start_time }}</div>
                  </template>
                  <template v-if="column.key === 'max_start_time'">
                    <div>{{ record.max_start_time }}</div>
                  </template>
                  <template v-if="column.key === 'min_end_time'">
                    <div>{{ record.min_end_time }}</div>
                  </template>
                  <template v-if="column.key === 'end_time'">
                    <div>{{ record.end_time }}</div>
                  </template>
                  <template v-if="column.key === 'max_end_time'">
                    <div>{{ record.max_end_time }}</div>
                  </template>
                  <template v-if="column.key === 'break_time'">
                    <div>{{ record.break_time }}</div>
                  </template>
                  <template v-else-if="column.key === 'active'">
                    <div class="text-center">
                      <div class="dropdown action-label">
                        <a
                          class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          href="javascript:;"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        > 
                          <i v-if="record?.active != null" class="fa-regular fa-circle-dot" :class="record?.active == 1 ? 'text-success' : 'text-danger' "></i> {{ record?.active == 1 ? 'Active' : record?.active == 0 ? 'InActive' : ''  }}
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a v-if="record?.active !== 0" class="dropdown-item" href="javascript:;"
							              @click="updateStatus(record,0)"
                          >
                            <i class="fa-regular fa-circle-dot text-danger"></i> InActive
                          </a>
                          <a v-if="record?.active !== 1" class="dropdown-item" href="javascript:;"
							              @click="updateStatus(record,1)"
                          >
                            <i class="fa-regular fa-circle-dot text-success"></i> Active
                          </a>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'is_default'">
                    <div class="input-block mb-3">
                      <div class="form-switch">
                        <input
                          @change="changeDefaultShift($event,record)"
                          type="checkbox"
                          class="form-check-input"
                          id="customSwitch1"
                          value="true"
                          :checked="record.is_default"
                        />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
                      <div class="dropdown dropdown-action" v-if="hasEditPermission || hasDeletePermission">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a v-if="hasEditPermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#add_shift"
                            @click="onEditShift(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_employee"
                            @click="onDeleteShift(record.shift_id)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i>
                            Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /Content End -->
      </div>
      <!-- /Page Content -->

      
      <shift-list-model @reload-data="reloadData" @delete-shift="deleteShift" :deleteId="deleteID" :editShift="editShift" :closeModal="closeModal"></shift-list-model>
      <edit-schedule-model :editSchedule="editSchedule" @add-schedule="openAddScheduleModel"></edit-schedule-model>
      <dept-schedule-model
        @add-dept-schedule="openAddDeptScheduleModel"
        @reload-data="reloadData"
      ></dept-schedule-model>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { notification } from "ant-design-vue";
import axios from 'axios';
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10};

const columns = [
  {
    title: "#",
    dataIndex: "id",
    key:"shift_index",
    customRender: ({ index }) => {
		  return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Shift Name",
    dataIndex: "shift_name",
    key: "shift_name",
    sorter: {
      compare: (a, b) => {
        a = a.shift_name.toLowerCase();
        b = b.shift_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Min Start Time",
    dataIndex: "min_start_time",
    sorter: {
      compare: (a, b) => {
        a = a.min_start_time.toLowerCase();
        b = b.min_start_time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Start Time",
    dataIndex: "start_time",
    sorter: {
      compare: (a, b) => {
        a = a.start_time.toLowerCase();
        b = b.start_time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Max Start Time",
    dataIndex: "max_start_time",
    sorter: {
      compare: (a, b) => {
        a = a.max_start_time.toLowerCase();
        b = b.max_start_time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Min End Time",
    dataIndex: "min_end_time",
    sorter: {
      compare: (a, b) => {
        a = a.min_end_time.toLowerCase();
        b = b.min_end_time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "End Time",
    dataIndex: "end_time",
    sorter: {
      compare: (a, b) => {
        a = a.end_time.toLowerCase();
        b = b.end_time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Max End Time",
    dataIndex: "max_end_time",
    sorter: {
      compare: (a, b) => {
        a = a.max_end_time.toLowerCase();
        b = b.max_end_time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Break Time",
    dataIndex: "break_time",
    sorter: {
      compare: (a, b) => {
        a = a.break_time;
        b = b.break_time;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    sorter: {
      compare: (a, b) => {
        a = a.active;
        b = b.active;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Is Default ?",
    key: "is_default",
    sorter: {
      compare: (a, b) => {
        a = a.is_default;
        b = b.is_default;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
export default {
  data() {
    return {
      columns:columns,
      data:null,
      title: "Shift List",
      path: "Dashboard",
      text: "Employees",
      text1: "Shift List",
      text2: "Add Shifts",
      text3: "Assign Shifts",
      text4: "Assign Department Shifts",
      perpage: 10,
      pagination: pagination,
      addDeptId: null,
      deleteID:null,
      closeModal:false,
      editShift:null,
      editSchedule:null,
      hasReadPermission:null,
      hasEditPermission:null,
      hasDeletePermission:null
    };
  },
  methods:{
    ...mapActions(['getShiftList']),
    updateStatus(record,status)
    {
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});

      var postform = new FormData();
      postform.append('active',status);	

      axios.put("/shifts/update-shift-status/"+record?.shift_id, postform).then( (response) => {
				
        loader.hide();
        
        notification.open({
          message: response.data.message,
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_SUCCESS_COLOR,
          },
        });

        record.active = status
            
      }).catch(error => {
          
          loader.hide();
          
         if(error?.response){
         
           var response = (error?.response);
             
           notification.open({
             message: response?.data?.message,
             placement: "topRight",
             duration: process.env.VUE_APP_NOTIFICATION_DURATION,
             style: {
               background: process.env.VUE_APP_WARNING_COLOR,
             },
           });
           
         }else{
           
           notification.open({
             message: 'Server Error',
             placement: "topRight",
             duration: process.env.VUE_APP_NOTIFICATION_DURATION,
             style: {
               background: process.env.VUE_APP_WARNING_COLOR,
             },
           });
         }
         
      });

    },
    handleTableChange(pagesize){
      var params = {
        params: { per_page: pagesize.pageSize,page:pagesize.current }
      };
      this.updatePerPage(params);
    },
    reloadData(){
      this.updatePerPage();
    },  
    async updatePerPage(params = null){
      var param = null;
      if(!params)
      {
        param = {
          params: { per_page: this.pagination.pageSize }
        };
      }
      else
      {
        param = params
      }
      
      const fetchedData = await this.getShiftList(param);

      if(fetchedData?.status && (fetchedData?.status != 200 || fetchedData?.status != 201))
      {
        notification.open({
            message: fetchedData?.data?.message || 'Data not found',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      else
      {
        this.pagination.total = fetchedData?.total;
        this.pagination.current = fetchedData?.current_page;
        this.pagination.pageSize = fetchedData?.per_page;
        if (fetchedData?.data !== null && fetchedData?.data !== undefined && Array.isArray(fetchedData?.data)) {
          this.data = fetchedData?.data;
        }
      }
    },
    onDeleteShift(id){
      this.deleteID = id
    },
    async deleteShift(id)
    {
      this.closeModal = false;
      if(id != null)
      {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
       
        await axios.delete("/shifts/"+id, [])
          .then((response) => {
            notification.open({
              message: response?.data?.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
            this.closeModal = true;
            this.deleteID = null
           
			      this.updatePerPage();
					
        }).catch(error => {
            this.closeModal = true;
             this.deleteID = null
            if(error.response){
            
              var response = (error.response);
                
              notification.open({
                message: response?.data?.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        })
      }
    },
    onEditShift(record){
      this.editShift = null; 
      this.$nextTick(()=>{
        this.editShift = record
      })
     
    },
    openAddShiftModel()
    {
      this.editShift = null
    },
    openAddScheduleModel()
    {
      this.editSchedule = null;
    },
    openAddDeptScheduleModel()
    {
      this.editSchedule = null;
    },
    changeDefaultShift(event,record)
    {
      const data = {
        shift_id : record?.shift_id,
        is_default: event?.target?.checked
      }

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;


      axios.put("/shifts/update-default-shift/"+record?.shift_id, data).then(response => {

        this.updatePerPage();

        notification.open({
            message: response?.data?.message || 'Successfully updated',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });

      }).catch(error => {
            if(error.response){

              event.target.checked = !event.target.checked
            
              var response = (error.response);
                
              notification.open({
                message: response?.data?.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
            
          }else{
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        })
    }
  },
  async created(){
    let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: false
			});

      this.$store.dispatch('userPermissions', { module: 'shift', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
        if(response)
        {
          this.columns = [...columns,{
            title: "Action",
            sorter: false,
            key: "action",
            class: "text-end",
          }];
        }
      })
    })
    
    this.$store.dispatch('userPermissions', { module: 'shift', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'shift', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })
		
    await this.updatePerPage();
    loader.hide();
  }



};
</script>
