<template>
  <!-- Add Department Schedule Modal -->
  <div id="add_dept_schedule" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Department Schedule</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="adddeptclose"
            @click="clickCloseModel"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Department <span class="text-danger">*</span></label
                >
                <select
                  @change="handleChangeEvent('department_id')"
                  v-model="scheduleForm.department_id"
                  class="form-select"
                  placeholder="Select"
                  :class="{
                    'is-invalid': clientError.includes('department_id'),
                  }"
                >
                  <option
                    v-for="dprtmnt in Departments"
                    :key="dprtmnt?.id"
                    :value="dprtmnt?.id"
                  >
                    {{ dprtmnt?.name }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  v-if="
                    formErrors &&
                    formErrors?.department_id &&
                    formErrors?.department_id != null
                  "
                  >{{ formErrors?.department_id[0] }}</span
                >
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-block mb-3">
                <label class="col-form-label">Date</label>
                <div class="cal-icon">
                  <datepicker
                    v-model="scheduleForm.date"
                    placeholder="Choose Date"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    :class="{ 'is-invalid': clientError.includes('date') }"
                    @change="handleChangeEvent('date')"
                  />
                </div>
                <span
                  class="text-danger"
                  v-if="
                    formErrors && formErrors?.date && formErrors?.date != null
                  "
                  >{{ formErrors?.date[0] }}</span
                >
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Select Shifts <span class="text-danger">*</span></label
                >
                <span
                  class="text-danger"
                  v-if="
                    formErrors &&
                    formErrors?.shift_id &&
                    formErrors?.shift_id != null
                  "
                  >{{ " " + formErrors?.shift_id[0] }}</span
                >
              </div>
            </div>
          </div>

          <div class="row shift_radio">
            <div
              class="col-md-3 col-lg-3 col-sm-3 p-0"
              v-for="shift in Shifts"
              :key="shift?.shift_id"
            >
              <label>
                <input
                  ref="shift_change_event"
                  type="radio"
                  name="shift_id"
                  v-model="scheduleForm.shift_id"
                  class="card-input-element"
                  :value="shift?.shift_id"
                />

                <div class="card card-default card-input">
                  <div class="card-header">{{ shift?.shift_name }}</div>
                  <div class="card-body">
                    <span class="username-info m-b-10"
                      >{{ convertUTCToLocal(shift?.start_time) }} -
                      {{ convertUTCToLocal(shift?.end_time) }} ({{
                        shift?.start_time && shift?.end_time
                          ? fetchTotalHours(shift?.start_time, shift?.end_time)
                          : ""
                      }})</span
                    >
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div class="submit-section">
            <button
              class="btn btn-primary submit-btn"
              :disable="disableButton"
              @click="onManageShift()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Department Schedule Modal -->
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import { notification } from "ant-design-vue";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
export default {
  emits: ["reload-data", "add-dept-schedule"],
  props: {
    closeModal: [Boolean],
  },
  data() {
    return {
      reloadShiftDetail: true,
      scheduleForm: {
        department_id: null,
        date: null,
        shift_id: null,
      },
      Shift: [],
      startdate: currentDate,
      dateFormat: "MM-dd-yyyy",
      startdateone: currentDateOne,
      Shifts: [],
      disableButton: false,
      formErrors: [],
      clientError: [],
      hasDeletePermission: null,
    };
  },
  methods: {
    ...mapActions([
      "fetchAllDepartments",
      "getAllShiftList",
      "manageDeptSchedule",
    ]),

    convertUTCToLocal(time) {
      if (time) {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        // Determine AM or PM
        const period = hours >= 12 ? "PM" : "AM";

        let m = minutes;

        // Convert hours to 12-hour format
        let hours12 = hours % 12;
        hours12 = hours12 === 0 ? 12 : hours12; // 0 should be treated as 12 in 12-hour format
        if (m < 10) {
          m = "0" + m;
        }

        if (hours12 < 10) {
          hours12 = "0" + hours12;
        }

        // Construct the formatted time string
        const formattedTime = `${hours12}:${m}`;

        // Return the formatted time with period
        return `${formattedTime} ${period}`;
      }
      return null;
    },
    fetchTotalHours(startTime, endTime) {
      if (startTime && endTime) {
        const startDate = new Date(`2000-01-01 ${startTime}`);
        const endDate = new Date(`2000-01-01 ${endTime}`);
        // Calculate the time difference in milliseconds
        const timeDifference = endDate - startDate;

        // Calculate hours and minutes
        const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
        const totalMinutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );

        // Format the result
        const formattedResult = `${totalHours} hrs ${totalMinutes} min`;

        return formattedResult;
      }
    },
    handleChangeEvent(target) {
      this.clientError = this.clientError.filter((item) => item !== target);
    },

    async onManageShift() {
      this.clientError = [];
      this.disableButton = true;
      var formData = {
        department_id: null,
        date: null,
        shift_id: null,
      };

      formData = { ...this.scheduleForm };

      formData.date = null;

      if (!this.scheduleForm.department_id == "") {
        formData.department_id = this.scheduleForm.department_id;
      } else {
        this.clientError.push("department_id");
      }

      if (this.scheduleForm.shift_id != "") {
        formData.shift_id = this.scheduleForm.shift_id;
      } else {
        this.clientError.push("shift_id");
      }

      if (this.scheduleForm?.date) {
        const endonDate = new Date(this.scheduleForm?.date);
        if (!isNaN(endonDate.getTime())) {
          formData.date =
            endonDate.getFullYear() +
            "-" +
            (endonDate.getMonth() + 1) +
            "-" +
            endonDate.getDate();
        }
      } else {
        formData.date = null;
        this.clientError.push("date");
      }

      if (this.scheduleForm?.schedule_id != null) {
        formData.schedule_id = this.scheduleForm?.schedule_id;
        formData.id = this.scheduleForm?.schedule_id;
      }

      this.disableButton = false;

      if (!this.clientError?.length) {
        const response = await this.manageDeptSchedule(formData);

        this.disableButton = false;

        if (response?.error && response?.error != null) {
          this.formErrors = response?.error;
          notification.open({
            message: response?.message || "Failed to Schedule Shift",
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        } else if (response?.status == 200) {
          this.$emit("reload-data");
          notification.open({
            message: response?.data?.message || "Successfully Schedule Added",
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });

          this.scheduleForm = {
            department_id: null,
            date: null,
            shift_id: null,
          };

          this.$refs?.adddeptclose.click();
        }
      }
    },
    clickCloseModel() {
      this.scheduleForm = {
        department_id: null,
        date: null,
        shift_id: null,
      };
    },
  },
  async created() {
    await this.fetchAllDepartments();
    this.Shifts = await this.getAllShiftList();
  },

  computed: {
    ...mapGetters(["getAllDepartments"]),
    Departments() {
      return this.getAllDepartments ? this.getAllDepartments : [];
    },
  },
};
</script>

<style scoped>
.shift_radio label {
  width: 100%;
}

.shift_radio .card-input-element {
  display: none;
}

.shift_radio .card-input {
  margin: 10px;
  padding: 0px;
}

.shift_radio .card-input:hover {
  cursor: pointer;
}

.shift_radio .card-input-element:checked + .card-input {
  box-shadow: 0 0 1px 1px #2ecc71;
}
</style>
