<template>
  <div class="card mb-0">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="profile-view">
            <div class="profile-img-wrap">
              <div class="profile-img">
                <a href="javascript:;"
                  >
                  <img
                      v-if="avatarSrc"
                      :src="avatarSrc"
                      alt="user"
                      @error="handleImageError()"
                    />
                </a>
              </div>
            </div>
            <div class="profile-basic">
              <div class="row">
                <div class="col-md-5">
                  <div class="profile-info-left">
                    <h3 class="user-name m-t-0 mb-0">{{ employeeData?.first_name }} {{ employeeData?.last_name }}</h3>
                    <small class="text-muted">{{ employeeData?.designation?.name }}</small>
					<div class="small doj text-muted"> Department : {{ employeeData?.department?.name || '' }}</div>
                    <div class="staff-id">Employee ID : {{ employeeData?.employee_id }}</div>
                    <div class="small doj text-muted">Date of Join : {{ formatDate(employeeData?.joining_date) || employeeData?.joining_date }}</div>
                    <div class="small doj text-muted"> Office : {{ employeeData?.office?.office_name || '' }}</div>

                    <!-- <div class="staff-msg">
                      <router-link class="btn btn-custom" to="chat" :disabled="true"
                        >Send Message</router-link
                      >
                    </div> -->
                  </div>
                </div>
                <div class="col-md-7">
                  <ul class="personal-info">
                    <li>
                      <div class="title">Phone:</div>
                      <div class="text"><a href="javascript:;">{{ employeeData?.contact_number }}</a></div>
                    </li>
                    <li>
                      <div class="title">Email:</div>
                      <div class="text"><a href="javascript:;">{{ employeeData?.email }}</a></div>
                    </li>
                    <li>
                      <div class="title">Birthday:</div>
                      <div class="text">{{ formatDate(employeeData?.birth_date) || employeeData?.birth_date }}</div>
                    </li>
                    <li>
                      <div class="title">Address:</div>
                      <div class="text">
                        {{ employeeData?.address }}
                      </div>
                    </li>
                    <li>
                      <div class="title">Gender:</div>
                      <div class="text"> {{ (employeeData?.gender != null) ? getGenderValue : '' }}</div>
                    </li>
                    <li>
                      <div class="title">Reports to:</div>
                      <div class="text">
                        <div class="avatar-box">
                          <div class="avatar avatar-xs"> 
                            <img
                             v-if="avatarReporterSrc && employeeData?.reporter?.id"
                              :src="avatarReporterSrc"
                              alt="User Image"
                              @error="handleReportImageError()"
                            />
                          </div>
                        </div>
                        <router-link :to="'/profile/'+employeeData?.reporter?.id"> {{ employeeData?.reporter?.first_name }} {{ employeeData?.reporter?.last_name }} </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="pro-edit">
              <a
                @click="employee()"
                data-bs-target="#profile_info"
                data-bs-toggle="modal"
                class="edit-icon"
                href="javascript:;"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  emits:['set-emp-data'],
  props:{
    employeeData: {
      type: Object,
      default: null,
    },
  },
  data(){
    return{
      genders:[{
        key:'f',
        value:'Female'
      },{
        key:'m',
        value:'Male'
      },
      {
        key:'o',
        value:'Others'
      }]
    };
    },
  computed: {
    getGenderValue() {
      const gender = this.genders.find(g => g.key === this.employeeData.gender);
      return gender ? gender.value : '';
    },
    avatarSrc() {
    return this.employeeData?.avatar
      ? process.env.VUE_APP_IMAGE_BASE_URL+this.employeeData?.avatar
      : require('@/assets/img/profiles/avatar-02.jpg');
    },
    avatarReporterSrc() {
    return this.employeeData?.reporter?.avatar
      ? process.env.VUE_APP_IMAGE_BASE_URL+this.employeeData?.reporter?.avatar
      : require('@/assets/img/profiles/avatar-02.jpg');
    },
  },  
  methods:{
    ...mapActions(['fetchEmployees','setEmployeeData']),
    employee(){
      this.setEmployeeData(this?.employeeData);  
      this.$emit('set-emp-data')
    },
    handleImageError() {
      this.employeeData.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    handleReportImageError(){
      this.employeeData.reporter.avatar = `assets/img/profiles/avatar-02.jpg`;
    }
  }
  }

</script>